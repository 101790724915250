import React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import NavBar from "../components/NavBar"
import Footer from "../components/Footer"
import Metatags from "../components/metatags"
import UseSiteMetadata from "../components/UseSiteMetadata"

import "../css/portfolio.scss"

const IndexEN = ({ pageContext, data, location }) => {
  const { siteUrl } = UseSiteMetadata()
  const fullUrl = `${siteUrl}${location.pathname}`
  const schemaData = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@context": "https://schema.org",
        "@type": "Organization",
        email: "info@netbistrot.com",
        name: "NetBistrot",
        logo: "https://netbistrot.com/netbistrot-icon-540.png",
      },
      {
        "@type": "WebSite",
        "@id": `https://netbistrot.com${location.pathname}`,
        url: `https://netbistrotcom${location.pathname}`,
        name: "NetBistrot",
        inLanguage: "pt-BR",
      },
    ],
  }
  return (
    <>
      <Metatags
        title="NetBistrot - Digital agency | portfolio"
        description="A sample of NetBistrot's work"
        url={fullUrl}
        image="https://netbistrot.com/netbistrot-icon-540.png"
        keywords="websites, pwa, social media, graphic design"
        language={pageContext.langKey}
        schemaData={schemaData}
      />
      <NavBar />
      <div className="container mx-auto">
        <div className="mx-2 md:mx-0">
          <div className="h-10" />
          <div className="grid grid-cols-1 md:grid-cols-12 gap-x-4 gap-y-8">
            <div className="col-span-1" />
            <div className="col-span-10">
              <h1>Uma amostra de nosso trabalho</h1>
              <div className="h-8" />
              <p>Online</p>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-12">
            <div className="col-span-2" />
            <div className="col-span-8">
              <div className="grid grid-cols-1 md:grid-cols-8 gap-x-4 gap-y-8">
                <div className="col-span-4">
                  <Link to="/pt/portfolio/speckle-park-brasil/">
                    <div className="brand-wrapper">
                      <div className="brand-image-wrapper">
                        <div className="img-hover-zoom">
                          <GatsbyImage
                            image={getImage(data.spb)}
                            backgroundColor={false}
                            alt="Speckle Park Brasil"
                          />
                        </div>
                      </div>
                      <div className="text-center brand-button-wrapper">
                        <button className="brand-button">
                          Speckle Park Brasil
                        </button>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-span-4">
                  <Link to="/pt/portfolio/troncal-radio/">
                    <div className="brand-wrapper">
                      <div className="brand-image-wrapper">
                        <div className="img-hover-zoom">
                          <GatsbyImage
                            image={getImage(data.troncalRadio)}
                            backgroundColor={false}
                            alt="Takeno Camargo e Ramalho"
                          />
                        </div>
                      </div>
                      <div className="text-center brand-button-wrapper">
                        <button className="brand-button">Troncal Radio</button>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-span-4">
                  <Link to="/pt/portfolio/takeno-camargo-e-ramalho/">
                    <div className="brand-wrapper">
                      <div className="brand-image-wrapper">
                        <div className="img-hover-zoom">
                          <GatsbyImage
                            image={getImage(data.tcr)}
                            backgroundColor={false}
                            alt="Takeno Camargo e Ramalho"
                          />
                        </div>
                      </div>
                      <div className="text-center brand-button-wrapper">
                        <button className="brand-button">
                          Takeno Camargo e Ramalho Advogadas
                        </button>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-span-4">
                  <Link to="/pt/portfolio/daucodesigns/">
                    <div className="brand-wrapper">
                      <div className="brand-image-wrapper">
                        <div className="img-hover-zoom">
                          <GatsbyImage
                            image={getImage(data.daucodesigns)}
                            backgroundColor={false}
                            alt="daucodesigns"
                          />
                        </div>
                      </div>
                      <div className="text-center brand-button-wrapper">
                        <button className="brand-button">daucodesigns</button>
                      </div>
                    </div>
                  </Link>
                </div>

                <div className="col-span-4">
                  <Link to="/pt/portfolio/speedcam/">
                    <div className="brand-wrapper">
                      <div className="brand-image-wrapper">
                        <div className="img-hover-zoom">
                          <GatsbyImage
                            image={getImage(data.speedcam)}
                            backgroundColor={false}
                            alt="peedcam brasil"
                          />
                        </div>
                      </div>
                      <div className="text-center brand-button-wrapper">
                        <button className="brand-button">
                          speedcam brasil
                        </button>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-span-4">
                  <Link to="/pt/portfolio/multilogica/">
                    <div className="brand-wrapper">
                      <div className="brand-image-wrapper">
                        <div className="img-hover-zoom">
                          <GatsbyImage
                            image={getImage(data.multilogica)}
                            backgroundColor={false}
                            alt="Multilógica"
                          />
                        </div>
                      </div>
                      <div className="text-center brand-button-wrapper">
                        <button className="brand-button">Multilógica</button>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-span-4">
                  <Link to="/pt/portfolio/clickdobem/">
                    <div className="brand-wrapper">
                      <div className="brand-image-wrapper">
                        <div className="img-hover-zoom">
                          <GatsbyImage
                            image={getImage(data.clickdobem)}
                            backgroundColor={false}
                            alt="click do bem"
                          />
                        </div>
                      </div>
                      <div className="text-center brand-button-wrapper">
                        <button className="brand-button">click do bem</button>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="h-10" />
          <div className="grid grid-cols-1 md:grid-cols-12 gap-x-4 gap-y-8">
            <div className="col-span-1" />
            <div className="col-span-10">
              <p>em breve</p>
            </div>
          </div> */}
          <div className="grid grid-cols-1 md:grid-cols-12">
            <div className="col-span-2" />
            <div className="col-span-8">
              <div className="grid grid-cols-1 md:grid-cols-8 gap-x-4 gap-y-8">
                {/* <div className="col-span-4">
                  <Link to="/pt/portfolio/multicast/">
                    <div className="brand-wrapper">
                      <div className="brand-image-wrapper">
                        <div className="img-hover-zoom">
                          <GatsbyImage
                            image={getImage(data.multicast)}
                            backgroundColor={false}
                            alt="MultiCAST"
                          />
                        </div>
                      </div>
                      <div className="text-center brand-button-wrapper">
                        <button className="brand-button">MultiCAST</button>
                      </div>
                    </div>
                  </Link>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="h-10" />
      <Footer lang="en" />
    </>
  )
}

export default IndexEN

export const query = graphql`
  query pageDataPT {
    daucodesigns: file(relativePath: { eq: "webs/web-daucodesigns.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
    clickdobem: file(relativePath: { eq: "webs/web-clickdobem.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
    speedcam: file(relativePath: { eq: "webs/web-speedcam-brasil.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
    multicast: file(relativePath: { eq: "webs/web-multicast.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
    multilogica: file(relativePath: { eq: "webs/web-multilogica.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
    tcr: file(relativePath: { eq: "webs/web-tcr.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
    troncalRadio: file(relativePath: { eq: "webs/web-troncal-radio.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
    spb: file(relativePath: { eq: "webs/spb.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
  }
`
